import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Step2.css';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import {
  signatureSelector,
  setQrCode,
  setParaphe,
  setSignataire,
  setHaveSignataire,
  setHaveOrder
} from '../../../../../services/reduxSlices/SignatureSlice';

import { useTranslateContext } from '../../../../../hooks';

//https://stackoverflow.com/a/51089016
const removeDuplicateArrayByKey = (arr) => {
  return [...new Set(arr.map((itm) => JSON.stringify(itm)))].map((i) =>
    JSON.parse(i)
  );
};

/**
 * Composant d'ajout de signataires de l'étape 2 de la fenêtre modale faire signer
 */
export default function Step2() {
  const { translate } = useTranslateContext();

  const dispatch = useDispatch();
  const signature = useSelector(signatureSelector);

  const [showRang, setShowRang] = useState(false);
  const [useAuthCode] = useState(true);
  const [signataireId, setSignataireId] = useState(1);
  const [_signataire, _setSignataire] = useState({
    nom: '',
    email: '',
    telephone: '',
    deadline: ''
  });

  const handleDisplayAuthCodeInput = () =>
    useAuthCode ? 'form-control d-block ms-5' : 'd-none';

  const handleAddSignataire = () => {
    if (
      _signataire.nom === '' ||
      _signataire.email === '' ||
      _signataire.telephone === ''
    ) {
      return alert('Veuillez remplir tous les champs');
    }

    if (!new RegExp(/^[^\s@]+@[^\s@]+$/).test(_signataire.email)) {
      return alert("L' adresse email est inccorrecte");
    }

    if (isNaN(_signataire.telephone) || _signataire.telephone.length !== 10) {
      return alert('Le numéro de téléphone ne doit contenir que 10 chiffres');
    }

    let signataires = signature.signataire.filter((signataire) => {
      return (
        signataire.email === _signataire.email ||
        signataire.telephone === _signataire.telephone
      );
    });

    if (signataires.length > 0) {
      return alert('Ce signataire a déjà été ajouté');
    }

    _setSignataire({
      nom: '',
      email: '',
      telephone: '',
      deadline: ''
    });

    setSignataireId(signataireId + 1);
    dispatch(setHaveSignataire(true));

    let _signataires = [
      ...signature.signataire,
      { ..._signataire, id: signataireId },
    ];

    //on retire l'attribut "read only" du tableau
    _signataires = JSON.parse(JSON.stringify(_signataires));

    //on refait la numérotation de l'id
    _signataires = _signataires.map((_signataire, i) => {
      _signataire.id = i + 1
      return _signataire
    })

    dispatch(setSignataire(_signataires));
  };

  const handleRemoveSignataire = (id) => {
    dispatch(setSignataire(signature.signataire.filter(signataire => signataire.id !== id)))
  }

  return (
    <>
      <div className="d-flex flex-column">
        <div className="row">
          <div className="col">
            <input
              type="search"
              className="form-control"
              value={_signataire.nom}
              onChange={(e) =>
                _setSignataire({ ..._signataire, nom: e.target.value })
              }
              placeholder={translate.admin.fullName}
            />
          </div>

          <div className="col">
            <input
              type="search"
              className="form-control"
              value={_signataire.email}
              onChange={(e) =>
                _setSignataire({ ..._signataire, email: e.target.value })
              }
              placeholder={translate.admin.email}
            />
          </div>

          <div className="col">
            <input
              type="search"
              className="form-control"
              value={_signataire.telephone}
              onChange={(e) =>
                _setSignataire({ ..._signataire, telephone: e.target.value })
              }
              placeholder={translate.admin.telephone}
            />
          </div>

          <div className="col">
            <button
              type="button"
              onClick={() => handleAddSignataire()}
              className="btn btn-success px-4"
              disabled={signature.signataire.length === 5}
            >
              {translate.admin.ajouter}
            </button>
            <button
              type="button"
              className="btn btn-primary ms-3 px-5"
              disabled={signature.signataire.length === 5}
              title="Importer des contacts"
              data-bs-toggle="modal"
              data-bs-target="#import-contacts"
            >
              <PermContactCalendarIcon />
            </button>
          </div>
        </div>

        <div className="py-5">
          {signature.signataire.length > 0 && (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    <input
                      style={{ height: 17, width: 17 }}
                      type="checkbox"
                      defaultChecked={showRang}
                      onChange={() => {
                        setShowRang(!showRang);
                        dispatch(setHaveOrder(!showRang));
                      }}
                    />
                  </th>
                  {showRang && <th scope="col">Rang(s)</th>}
                  <th scope="col">Nom et prénom(s)</th>
                  <th scope="col">Email</th>
                  <th scope="col">Téléphone</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {signature.signataire.map((signataire) => {
                  return (
                    <tr key={signataire.id}>
                      <td></td>
                      {showRang && (
                        <td>
                          <input
                            type="number"
                            min={1}
                            max={signature.signataire.length}
                            className="rang"
                            defaultValue={signataire.id}
                            contentEditable={false}
                            onChange={(e) => {

                            }}
                          />
                        </td>
                      )}
                      <td>{signataire.nom}</td>
                      <td>{signataire.email}</td>
                      <td>{signataire.telephone}</td>
                      <td>
                        <button
                          type="button"
                          onClick={() => handleRemoveSignataire(signataire.id)}
                          className="btn btn-danger d-flex rounded-circle justify-content-center align-items-center"
                          style={{
                            width: '10px',
                            height: '10px',
                            padding: '15px',
                          }}
                        >
                          X
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div className="mt-5">
        <div className="d-flex align-items-center mb-5">
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              value="paraphe"
              id="_paraphe"
              name="option"
              onClick={() => dispatch(setParaphe(!signature.paraphe))}
            />
            <label className="form-check-label" htmlFor="_paraphe">
              {translate.admin.paraphe}
            </label>
          </div>

          <div className="form-check form-check-custom form-check-solid mx-5">
            <input
              className="form-check-input"
              type="checkbox"
              value="qrcode"
              id="_qrcode"
              name="option"
              onClick={() => dispatch(setQrCode(!signature.qrCode))}
            />
            <label className="form-check-label" htmlFor="_qrcode">
              QR Code
            </label>
          </div>

          <div className="d-flex align-items-center">
            <div className="form-check form-check-custom form-check-solid">
              <label className="form-check-label" htmlFor="_code">
                {translate.admin.codeAuthentification}
              </label>
            </div>

            <input
              type="number"
              className={handleDisplayAuthCodeInput() + ' w-25'}
              value={signature.authCode}
            />
          </div>
        </div>
      </div>
    </>
  );
}

// onChange={e => dispatch(setAuthCode(e.target.value))}
